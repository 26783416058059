<template>
  <div class="goods-page-container">
    <market-header @change="headerParamChange" tab-base-url="/shop-goods/goods" />

    <en-table-layout ref="tableLayout" :tableData="pageData.data" :loading="loading" :toolbar-two="true"
      :toolbar-three="[2, 10].includes(params.self_goods) ? true : false" :redundancy-height="49"
      @selection-change="handleSelectionChange" @sort-change="handleSortChange">
      <template slot="toolbar" v-if="[2].includes(params.self_goods)">
        <el-row>
          <el-col :span="6">
            <el-form-item label="会员价">
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.low_price" clearable></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.high_price" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="利润率(%)">
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.profit_min" clearable></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.profit_max" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item>
              <el-input v-model.trim="searchContent" placeholder="请输入关键字进行搜索" style="width: 320px" clearable>
                <el-select style="width: 110px;" slot="prepend" v-model="key_word">
                  <el-option label="商品名称" value="goods_name"></el-option>
                  <el-option label="商品编号" value="goods_sn"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-button @click="searchEvent('')" size="small" type="primary">搜索</el-button>
            <el-button @click="selectGoodsAll" size="small" type="primary">批量挑选</el-button>
          </el-col>
        </el-row>
      </template>
      <template slot="toolbar" v-if="![2, 10].includes(params.self_goods)">
        <el-row>
          <el-col :span="7">
            <el-form-item label="会员价">
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.low_price" clearable></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.high_price" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item>
              <el-input v-model.trim="searchContent" placeholder="请输入关键字进行搜索" style="width: 320px" clearable>
                <el-select style="width: 120px;" slot="prepend" v-model="key_word">
                  <el-option label="商品名称" value="goods_name"></el-option>
                  <el-option label="商品编号" value="goods_sn"></el-option>
                  <el-option
                    v-show="((params.self_goods === 3 || params.self_goods === 0) && $store.getters.isSupplierName) || params.self_goods === 4"
                    label="所属供应商" value="seller_name"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-checkbox v-model="checked" @change="che">只看未挑选</el-checkbox>
            <el-button style="margin-left: 10px;" @click="searchEvent('')" size="small" type="primary">搜索</el-button>
            <el-button @click="selectGoodsAll" size="small" type="primary">批量挑选</el-button>
          </el-col>
        </el-row>
      </template>

      <template slot="toolbar-two" v-if="[2].includes(params.self_goods)">
        <el-row>
          <el-col :span="6">
            <el-form-item label="市场价">
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.low_mktprice" clearable></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.high_mktprice" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item v-if="params.self_goods === 2" label="商品配送类型">
              <el-select style="width: 145px" v-model="params.ship_method" placeholder="请选择配送类型" clearable>
                <el-option label="全部" value />
                <el-option label="由厂家配送" :value="2" />
                <el-option label="京东自营配送" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="商品分类">
              <el-cascader clearable style="width:250px" v-model="category" :options="categoryProxy.children"
                :props="categoryProxy.props" />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-checkbox v-model="checked" @change="che">只看未挑选</el-checkbox>
          </el-col>
        </el-row>
      </template>

      <template slot="toolbar-two" v-if="![2, 10].includes(params.self_goods)">
        <el-row>
          <el-col :span="7">
            <el-form-item label="市场价">
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.low_mktprice" clearable></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.high_mktprice" clearable></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="9">
            <el-form-item label="商品分类">
              <el-cascader clearable style="width:250px" v-model="category" :options="categoryProxy.children"
                :props="categoryProxy.props" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="利润率(%)">
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.profit_min" clearable></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input style="width: 75px;" size="medium" :controls="false" v-model="params.profit_max" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </template>

      <template slot="toolbar-three" v-if="[2].includes(params.self_goods)">
        <el-row>
          <el-col :span="24">
            <div class="address-border" style="padding:10px;border:1px solid #e9ebf0">
              <el-form-item label="显示类型" v-if="areaSelectData.province !== ''">
                <!-- v-if="areaSelectData.province!==''" -->
                <el-select size="small" class="choose-machine center" v-model="areaSelectData.jd_area_state"
                  placeholder="请选择" style="width:200px;" :disabled="areaSelectData.province === ''" clearable>
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="有货" :value="1"></el-option>
                  <el-option label="无货" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="地区" :style="{ width: areaNum * 120 + 60 + 'px' }">
                <el-select size="small" class="choose-machine center" v-model="areaSelectData.province"
                  placeholder="省/直辖市" style="width:110px;margin-right: 10px" clearable>
                  <el-option v-for="(item, index) in this.provinceList" :label="item.name" :value="item.id"
                    :key="index"></el-option>
                </el-select>
                <el-select v-if="areaNum > 1" size="small" class="choose-machine center" v-model="areaSelectData.city"
                  placeholder="城市/地区" style="width:110px;margin-right: 10px" clearable>
                  <el-option v-for="(item, index) in this.cityList" :label="item.name" :value="item.id"
                    :key="index"></el-option>
                </el-select>
                <el-select v-if="areaNum > 2" size="small" class="choose-machine center" v-model="areaSelectData.county"
                  placeholder="县/区" style="width:110px;margin-right: 10px" clearable>
                  <el-option v-for="(item, index) in this.countyList" :label="item.name" :value="item.id"
                    :key="index"></el-option>
                </el-select>
                <el-select v-if="areaNum > 3 && this.townList.length > 0" size="small" class="choose-machine center"
                  v-model="areaSelectData.town" placeholder="乡镇/街道" style="width:110px;margin-right: 10px" clearable>
                  <el-option v-for="(item, index) in this.townList" :label="item.name" :value="item.id"
                    :key="index"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </template>
      <!-- 商城套餐页签 -->
      <template slot="toolbar" v-if="[10].includes(params.self_goods)">
        <el-form-item label-width="83px" label="会员总价">
          <el-input clearable style="width: 90px;" size="medium" :controls="false"
            v-model.trim="giftParams.lowShopSumPrice" @blur="searchInput('lowShopSumPrice')" />
          <span style="margin: 0 4px;">-</span>
          <el-input clearable style="width: 90px;" size="medium" :controls="false"
            v-model.trim="giftParams.highShopSumPrice" @blur="searchInput('highShopSumPrice')" />
        </el-form-item>

        <el-form-item label="市场总价">
          <el-input clearable style="width: 90px;" size="medium" :controls="false" v-model.trim="giftParams.lowMktPrice"
            @blur="searchInput('lowMktPrice')" />
          <span style="margin: 0 4px;">-</span>
          <el-input clearable style="width: 90px;" size="medium" :controls="false"
            v-model.trim="giftParams.highMktPrice" @blur="searchInput('highMktPrice')" />
        </el-form-item>

        <el-form-item label="来源应用" class="col-auto" label-width="110px">
          <el-select style="width: 140px" v-model="giftParams.shop_type" size="small" placeholder="请选择" clearable>
            <el-option label="全部" value="" />
            <el-option label="智能套餐卡商城" :value="5" />
            <el-option label="小程序商城" :value="7" />
            <el-option label="双选商城" :value="6" />
          </el-select>
        </el-form-item>

        <el-form-item label="礼包分类" label-width="100px">
          <el-cascader clearable style="width: 215px;" v-model="giftCategoryParams" :options="JDCategory.children"
            :props="JDCategory.props" placeholder="请选择礼包分类" class="cas-select" />
        </el-form-item>
      </template>
      <!-- 商城套餐页签第二行 -->
      <template slot="toolbar-two" v-if="[10].includes(params.self_goods)">
        <el-form-item label="市场利润率">
          <el-input clearable style="width: 90px;" size="medium" :controls="false"
            v-model.trim="giftParams.lowMarketProfit" @blur="searchInput('lowMarketProfit')" />
          <span style="margin: 0 4px;">-</span>
          <el-input clearable style="width: 90px;" size="medium" :controls="false"
            v-model.trim="giftParams.highMarketProfit" @blur="searchInput('highMarketProfit')" />
        </el-form-item>
        <el-form-item label="区域偏好">
          <el-select size="small" filterable placeholder="请选择" v-model.trim="giftParams.labelNamesArea" clearable
            style="width: 194px">
            <el-option v-for="(item, index) in areaList" :key="index" :value="item.label_name"
              :label="item.label_name" />
          </el-select>
        </el-form-item>
        <el-form-item label="套餐类型" label-width="110px">
          <el-select v-model="giftParams.comboType" style="width: 140px" clearable>
            <el-option value label="全部"></el-option>
            <el-option :value="1" label="京东"></el-option>
            <el-option :value="2" label="线下品"></el-option>
            <el-option :value="3" label="京东+线下品"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item v-if="isGlzcShop" label="异常等级">
          <el-select v-model="giftParams.normal_status" style="width: 100px" clearable>
            <el-option value label="全部"></el-option>
            <el-option :value="0" label="一级"></el-option>
            <el-option :value="2" label="二级"></el-option>
            <el-option :value="1" label="正常"></el-option>
          </el-select>
          <el-tooltip effect="dark" placement="top-end">
            <div slot="content">
              一级异常：礼包内的原品有下架、无货的商品，且商品没有可替换商品（手动、自动都没有）；会以红色异常标签进行提示。
              <br/>
              二级异常：礼包内的原品有下架、无货的商品，有可替换商品（手动、或自动）；会以橙色异常标签进行提示。
            </div>
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </el-form-item> -->
      </template>
      <!-- 第三行商城套餐 -->
      <template slot="toolbar-three" v-if="[10].includes(params.self_goods)">
        <el-form-item label="套餐折扣" label-width="83px">
          <el-input clearable style="width: 90px;" size="medium" :controls="false"
            v-model.trim="giftParams.lowComboDiscount" @blur="searchInput('lowComboDiscount')" />
          <span style="margin: 0 4px;">-</span>
          <el-input clearable style="width: 90px;" size="medium" :controls="false"
            v-model.trim="giftParams.highComboDiscount" @blur="searchInput('highComboDiscount')" />
        </el-form-item>
        <el-form-item>
          <el-input clearable v-model.trim="searchContent" placeholder="请输入关键字进行搜索" style="width: 320px">
            <el-select style="width: 120px;" slot="prepend" v-model="key_word">
              <el-option label="商品名称" value="goods_name"></el-option>
              <el-option label="礼包名称" value="gift_name"></el-option>
              <el-option label="礼包编号" value="id"></el-option>
              <el-option label="所属商城" value="shop_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="checked" @change="che">只看未挑选</el-checkbox>
          <el-button style="margin-left: 20px;" @click="searchEvent('')" size="small" type="primary">搜索</el-button>
          <el-button @click="selectGoodsAll" size="small" type="primary">批量挑选</el-button>
          <!-- <el-dropdown @command="exportbatchOps" size="small" style="margin-left: 10px;">
            <el-button type="primary" size="small">
              导出套餐方案 <i class="el-icon-arrow-down el-icon--right ml-2"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown"> 
              <el-dropdown-item command="exportPPT">导出PPT方案1</el-dropdown-item>
              <el-dropdown-item command="twoExportPPT">导出PPT方案2</el-dropdown-item>
              <el-dropdown-item command="exportExcel">导出Excel方案</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </el-form-item>
      </template>
      <!-- 商城套餐列表 -->
      <template v-if="[10].includes(params.self_goods)" slot="table-columns">
        <el-table-column key="selectionGift" type="selection" />
        <el-table-column prop="id" width="120" key="giftid" label="礼包编号" fixed="left" />
        <el-table-column key="goods_cover" label="礼包图片" width="80" fixed="left" class-name="goods-cover-wrapper">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" alt style="width: 300px" />
              <div slot="reference">
                <img :src="row.thumbnail" class="goods-cover" alt />
                <img v-if="isGlzcShop" class="goods-tag" :src="getRowTagImage(row)" alt />
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column key="goods_name" label="礼包名称" min-width="200" :show-overflow-tooltip="true" fixed="left">
          <template slot-scope="scope">{{ scope.row.gift_name }}</template>
        </el-table-column>
        <el-table-column label="礼包分类" width="180" key="category_name" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.category_name }}
          </template>
        </el-table-column>
        <el-table-column label="商品种类" :key="'goods_kind_num'">
          <template slot-scope="scope">
            <span class="num" @click="handleComboGoodsNum(scope.row)">{{ scope.row.goods_kind_num }}</span>
          </template>
        </el-table-column>
        <el-table-column label="会员总价" width="110" prop="memberPrice" sortable="custom" :key="'sum_price'">
          <template slot-scope="scope" v-if="scope.row.sum_price">{{ scope.row.sum_price | unitPrice('￥') }}</template>
          <template v-else></template>
        </el-table-column>
        <el-table-column label="市场总价" width="110" prop="marketPrice" sortable="custom" :key="'market_sum_price'">
          <template slot-scope="scope" v-if="scope.row.market_sum_price">{{ scope.row.market_sum_price | unitPrice('￥')
            }}</template>
          <template v-else></template>
        </el-table-column>
        <el-table-column key="combo_discount" label="套餐折扣" width="130" sortable="custom" prop="comboDiscount">
          <template slot="header">
            <el-tooltip effect="dark" placement="top">
              <label class="cursor-pointer">套餐折扣</label>
              <div slot="content">套餐折扣 = 会员总价/市场总价*100%</div>
            </el-tooltip>
          </template>
          <template slot-scope="scope">{{ (scope.row.combo_discount || 0) | unitCompare('%') }}</template>
        </el-table-column>
        <el-table-column label="市场利润率" width="130" prop="marketProfit" sortable="custom" :key="'market_profit'">
          <template slot="header">
            <el-tooltip effect="dark" placement="top">
              <label class="cursor-pointer">市场利润率</label>
              <div slot="content">市场利润率 = (市场总价-会员总价)/市场总价*100%</div>
            </el-tooltip>
          </template>
          <template slot-scope="scope">{{ scope.row.market_profit ? scope.row.market_profit.toFixed(2) : 0
            }}%</template>
        </el-table-column>
        <el-table-column label="套餐类型" width="160" prop="combo_type" :key="'combo_type'">
          <template slot-scope="scope">
            <span v-if="scope.row.combo_type == 1">京东</span>
            <span v-else-if="scope.row.combo_type == 2">线下品</span>
            <span v-else-if="scope.row.combo_type == 3">京东+线下品</span>
          </template>
        </el-table-column>
        <el-table-column label="区域偏好" width="160" show-overflow-tooltip prop="label_names_area"
          :key="'label_names_area'" />
        <el-table-column label="标签" width="160" show-overflow-tooltip prop="label_names_gift"
          :key="'label_names_gift'" />
        <el-table-column label="来源应用" width="160" show-overflow-tooltip prop="shop_type" :key="'shop_type'">
          <template slot-scope="scope">{{ scope.row.shop_type | getShopType }}</template>
        </el-table-column>
        <el-table-column label="所属商城" width="160" show-overflow-tooltip prop="shop_name" :key="'shop_name'" />
        <el-table-column key="update_time" label="更新时间" width="130" prop="time">
          <template slot-scope="scope">{{ scope.row.update_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
        </el-table-column>
        <el-table-column label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="selectGoods(scope.row)">挑选</el-button>
          </template>
        </el-table-column>
      </template>
      <template v-else slot="table-columns">
        <el-table-column :key="'selection'" type="selection" />

        <el-table-column label="图片" width="60" fixed="left" class-name="goods-cover-wrapper">
          >
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" class="goods-cover big" alt style="width: 300px" />

              <div slot="reference" @click="opengoodsclassify(row)">
                <img :src="row.thumbnail" class="goods-cover" alt />
                <img class="goods-tag" :src="getRowTagImage(row)" alt />
              </div>

            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="商品名称" min-width="200" :show-overflow-tooltip="true" fixed="left">
          <template slot-scope="{row}">
            <span v-html="row.goods_name.replace(/\s/g, '&nbsp;')"></span>
          </template>
        </el-table-column>
        <el-table-column label="商品编号" min-width="200" prop="good_sn" :show-overflow-tooltip="true" fixed="left" />
        <el-table-column label="商品分类" min-width="200" :show-overflow-tooltip="true" prop="cat_name" />
        <el-table-column label="商品品牌" key="ext_brand_name" v-if="params.self_goods === 9" prop="ext_brand_name" width="150"
          :show-overflow-tooltip="true" />
        <el-table-column label="市场价" width="120">
          <template slot-scope="scope">
            <template>
              <span v-if="scope.row.goods_type === 'POINT'">
                <template v-if="scope.row.exchange_money">{{ scope.row.exchange_money | unitPrice('￥') }}</template>
                <template v-else></template>
                + {{ scope.row.exchange_point || 0 }}
                积分
              </span>
              <span v-else>
                <template v-if="scope.row.mktprice">{{ scope.row.mktprice | unitPrice('￥') }}</template>
                <template v-else></template>
              </span>
            </template>
          </template>
        </el-table-column>

        <el-table-column label="会员价" width="140" sortable="custom" prop="price">
          <template slot-scope="scope">
            <span v-if="scope.row.goods_type === 'POINT'">
              <template v-if="scope.row.exchange_money">{{ scope.row.exchange_money | unitPrice('￥') }}</template>
              <template v-else></template>
             +{{ scope.row.exchange_point || 0 }}
              积分
            </span>
            <span v-else>
              <template v-if="scope.row.price">{{ scope.row.price | unitPrice('￥') }}</template>
              <template v-else></template>
            </span>
          </template>
        </el-table-column>

        <el-table-column label="利润率" width="100" sortable="custom" prop="profit" v-if="is_qiye_flag">
          <template slot-scope="scope">{{ (scope.row.enterprise_profit || 0) | unitCompare('%') }}</template>
        </el-table-column>

        <el-table-column label="所属供应商" width="100" show-overflow-tooltip
          v-if="params.self_goods === 3 && $store.getters.isSupplierName">
          <template slot-scope="scope">
            {{ scope.row.seller_name }}
          </template>
        </el-table-column>

        <el-table-column label="上架时间" width="130" prop="time" sortable="custom">
          <template slot-scope="scope">{{ scope.row.up_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
        </el-table-column>

        <el-table-column label="库存" width="100">
          <template slot-scope="scope">
            <span v-if="quantityStatus">{{ scope.row.stock_state_desc }}</span>
            <template v-else-if="[1, 3].includes(scope.row.goods_source)">{{ scope.row.enable_quantity > 0 ? '有货' : '无货'
              }}</template>
            <template v-else>{{ scope.row.quantity||0 }}件</template>
            <!-- <span v-else>{{ scope.row.enable_quantity > 0 ? '有货' : '无货' }}</span> -->
          </template>
        </el-table-column>
        <el-table-column label="销量" width="100" sortable="custom" prop="buynum">
          <template slot-scope="scope">{{ scope.row.buy_count }}</template>
        </el-table-column>

        <el-table-column v-if="is_qiye_flag" label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="selectGoods(scope.row)">挑选</el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>

    <!--添加标签类型-->
    <el-dialog title="选择商品分类" :visible.sync="tagShow" width="600px">
      <div class="tagForm">
        <el-form label-width="180px">
          <el-select v-model="firstValue" placeholder="请选择一级分类" @change="choosefirst">
            <el-option v-for="item1 in firstData" :key="item1.cat_id" :label="item1.name"
              :value="item1.cat_id"></el-option>
          </el-select>

          <el-select v-model="secondValue" v-if="secondData.length > 0" placeholder="请选择二级分类" @change="choosesecond">
            <el-option v-for="item2 in secondData" :key="item2.cat_id" :label="item2.name"
              :value="item2.cat_id"></el-option>
          </el-select>

          <el-select v-model="thirdValue" v-if="thirdData.length > 0" placeholder="请选择三级分类" @change="choosethird">
            <el-option v-for="item3 in thirdData" :key="item3.cat_id" :label="item3.name"
              :value="item3.cat_id"></el-option>
          </el-select>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="primary" size="small" @click="tagShow = false">取消</el-button>
        <el-button type="primary" size="small" @click="leaveIsYse">确定</el-button>
      </div>
    </el-dialog>

    <en-gaijia-picker :showm="dialogShopShow1" @close="close" :shop_type="shopInfo.shop_type"
      @confirm1="handleShopPickerConfirm1" @res="res" :idsList="idsList" :allId="pageData.data_total"
      :is_qiye_flag="MixinIsEnterprise" :btnShow="false" />
    <!-- 商品详情弹窗 -->
    <el-dialog custom-class="goodsclassify" :visible.sync="dialogVisible" :close-on-click-modal="false"
      :close-on-press-escape="false" append-to-body width="375px">
      <div slot="title" class="goods-picker-title">
        <span></span>
        商品详情
      </div>
      <!-- <goodsclassify v-if="dialogVisible" :formData="goodsclassifyData"></goodsclassify> -->
      <goods-detail v-if="dialogVisible" :formData="goodsclassifyData"></goods-detail>
    </el-dialog>
    <en-gaijia-gift-picker :showm="dialogGiftShow" @close="close" @res="res" :shop_type="shopInfo.shop_type"
      @confirm1="handleGiftConfirm" :idsList="idsList" :allId="pageData.data_total" />
    <!--查看商品数量，商品展示-->
    <el-dialog-x-dialog title="商品展示" :visible.sync="comboGoodsNumVisible">
      <en-table-layout :table-data="comboGoodsNumTableData.data">
        <template slot="table-columns">
          <el-table-column label="商品图片" width="60" fixed="left" class-name="goods-cover-wrapper" :key="'thumbnail'">
            <template slot-scope="{row}">
              <el-popover placement="right" trigger="hover">
                <img :src="row.thumbnail" alt style="width: 300px" />
                <img :src="row.thumbnail" class="goods-cover" alt slot="reference" />
              </el-popover>
            </template>
          </el-table-column>
          <!-- <el-table-column label="前端展示图片" width="60" fixed="left" class-name="goods-cover-wrapper" :key="'web_thumbnail'">
            <template slot-scope="{row}">
              <el-popover placement="right" trigger="hover">
                <img :src="row.web_thumbnail ? row.web_thumbnail : row.thumbnail" alt style="width: 300px" />
                <img :src="row.web_thumbnail ? row.web_thumbnail : row.thumbnail" class="goods-cover" alt
                  slot="reference" />
              </el-popover>
            </template>
          </el-table-column> -->
          <el-table-column label="商品名称" :show-overflow-tooltip="true" fixed="left">
            <template slot-scope="scope">{{ scope.row.goods_name }}</template>
          </el-table-column>
          <!-- <el-table-column label="前端名称" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{ scope.row.goods_alias }}</template>
          </el-table-column> -->
          <el-table-column label="商品来源" width="100">
            <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
            <template slot-scope="{row}">
              <template v-if="row.goods_source === 0 && row.self_operated === 0">平台商品</template>
              <template v-if="row.goods_source === 0 && row.self_operated === 1">自有商品</template>
              <template v-if="row.goods_source === 1 && row.self_operated === 0">京东商品</template>
              <template v-else-if="[5, 9].includes(row.goods_source)">自有商品</template>
            </template>
          </el-table-column>
          <el-table-column v-if="$store.getters.isSupplierName" label="所属供应商">
            <template slot-scope="scope">{{ scope.row.supplier_name }}</template>
          </el-table-column>
          <el-table-column label="数量" width="90">
            <template slot-scope="scope">{{ scope.row.num }}</template>
          </el-table-column>
        </template>
      </en-table-layout>
    </el-dialog-x-dialog>
    <el-dialog-x-dialog width="750px" title="导出PPT方案" :visible.sync="exportPPTVisible">
      <el-form class="exportPPTForm" ref="exportPPTForm" :model="exportPPTForm" :rules="exportPPTFormRule"
        label-width="130px">
        <el-form-item style="margin-bottom:10px" label="套餐方案名称" prop="package_name">
          <el-input style="width:250px" v-model.trim="exportPPTForm.package_name" :maxlength="20" :minlength="1"
            placeholder="请输入套餐方案名称"></el-input>
        </el-form-item>
        <el-form-item style="margin:0" label="套餐礼包价格展示">
          <el-radio-group v-model="exportPPTForm.package_gift_price">
            <el-radio :label="0">市场总价</el-radio>
            <el-radio :label="1">销售总价</el-radio>
            <el-radio :label="2">分销总价</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin:0" label="套餐单品市场价">
          <el-radio-group v-model="exportPPTForm.package_goods_price">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin:0" label="PPT页面图片显示">
          <el-radio-group v-model="exportPPTForm.package_goods_img">
            <el-radio :label="0">单品图片</el-radio>
            <el-radio :label="1">套餐礼包图片</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin:0" label="导出套餐分类页面">
          <el-radio-group @input="giftCategoryChange" v-model="exportPPTForm.gift_category">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin:0" label="PPT背景图设置">
        </el-form-item>
        <div style="display:flex;">
          <el-form-item label="首页封面图">
            <el-upload class="site-logo" accept=".jpeg, .png, .jpg" :action="MixinUploadApi" :show-file-list="false"
              :on-success="(res) => { exportPPTForm.gift_home_img = res.url }" :multiple="false">
              <div v-if="exportPPTForm.gift_home_img">
                <img :src="exportPPTForm.gift_home_img" class="site-logo-img" />
                <i @click="exportPPTForm.gift_home_img = ''" class="el-icon-circle-close deleteImg"></i>
              </div>
              <i v-else class="el-icon-plus logo-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item v-if="exportPPTForm.gift_category == 1" label-width="90px" label="套餐分类图">
            <el-upload class="site-logo" accept=".jpeg, .png, .jpg" :action="MixinUploadApi" :show-file-list="false"
              :on-success="(res) => { exportPPTForm.gift_category_img = res.url }" :multiple="false">
              <div v-if="exportPPTForm.gift_category_img">
                <img :src="exportPPTForm.gift_category_img" class="site-logo-img" />
                <i @click="exportPPTForm.gift_category_img = ''" class="el-icon-circle-close deleteImg"></i>
              </div>
              <i v-else class="el-icon-plus logo-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label-width="120px" label="普通页面背景图">
            <el-upload class="site-logo" accept=".jpeg, .png, .jpg" :action="MixinUploadApi" :show-file-list="false"
              :on-success="(res) => { exportPPTForm.gift_background_img = res.url }" :multiple="false">
              <div v-if="exportPPTForm.gift_background_img">
                <img :src="exportPPTForm.gift_background_img" class="site-logo-img" />
                <i @click="exportPPTForm.gift_background_img = ''" class="el-icon-circle-close deleteImg"></i>
              </div>
              <i v-else class="el-icon-plus logo-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </div>
        <p style="padding-left:140px">提示：请上传1张大小不超10MB，格式为jpg、png、jpeg的图片。建议尺寸：1200*764。</p>
        <el-form-item style="text-align:right">
          <el-button @click="exportPPTVisible = false">取 消</el-button>
          <el-button type="primary" @click="exportPPTConfirm">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog-x-dialog>
    <el-dialog-x-dialog width="400px" title="提示" :visible.sync="errorGiftVisible">
      <div style="margin:10px 0 10px 10px">
        <p style="font-size:16px">以下礼包的礼包名称在所选商城中已经存在，请到对应商城中修改礼包名称后，再进行挑选操作。</p>
        <p>礼包名称：<span v-for="(item, index) in errorGiftMessage" :key="index">{{ item }}；</span></p>
      </div>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import * as API_goods from "@/api/goods";
import { CategoryPicker } from "@/components";
import SvgIcon from "@/components/SvgIcon";
import MarketHeader from "@/views/shopGoods/market-header";
import EnTableLayout from "../../../ui-components/TableLayout/src/main";
import TagZY from "@/assets/goods/tag-zy.png";
import TagJD from "@/assets/goods/tag-jd.png";
import TagJX from "@/assets/goods/tag-jx.png";
import { $goodsCategory } from "@/pages/goods/services/goods-category";
import goodsclassify from "@/components/GoodsClassify/goodsclassify";
import goodsDetail from "@/components/GoodsDetail/GoodsDetail";
import { $giftsCategory } from '@/pages/goods/services/gifts-category'
import { getGiftLabelList } from '@/api/account';
import statusErr from "@/assets/goods/statusErr.png";
export default {
  name: "goods",
  components: {
    EnTableLayout,
    MarketHeader,
    SvgIcon,
    CategoryPicker,
    goodsclassify,
    goodsDetail
  },
  data () {
    return {
      dialogVisible: false,//商品详情弹窗
      goodsclassifyData: {},//商品详情传参
      key_word: "goods_name",
      searchContent: "",
      categoryProxy: $goodsCategory.create(),
      category: "",
      market_header_search: "",
      checked: true,
      pageData: {
        page_no: 1,
        page_size: 20,
        data_total: 0
      },
      child1: [],
      child: [],
      index: 1,
      dialogShopShow1: false,
      dialogShopShow: false,
      aaaaa: "",
      idsList: [],
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      sort1: "",
      sort2: "",
      params: {
        page_no: 1,
        page_size: 20,
        // 以下不是京东馆参数
        high_price: "",
        low_price: "",
        high_mktprice: "",
        low_mktprice: "",
        profit_min: "",
        profit_max: "",
        self_goods: 0,
        // 排序
        sort: "",
        // 京东排序
        sort_type: "",
        category_path: "",
        keyword: "",
        filter_id: 1,
        // 京东馆价钱最大值和最小
        min: "",
        max: "",
        cid1: "", //一级分类
        cid2: "" //二级分类
      },
      /** 列表数据 */
      JDdepth: 2,
      PTdepth: 3,
      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      ac: 1,
      categoryListLevel1: [],
      tempList: [],
      shop_id: "",
      pa: {
        set_under_message: ""
      },
      fdList: [],
      // 京东馆商品分类
      goods_source: 0,
      areaNum: 1,
      provinceList: [],
      cityList: [],
      countyList: [],
      townList: [],
      areaSelectData: {
        province: "",
        city: "",
        county: "",
        town: ""
      },
      // 弹窗好像已经没地方使用了，如果你看到了，你可以把弹窗删掉
      tagShow: false,
      firstValue: "",
      secondValue: "",
      thirdValue: "",
      firstData: [],
      secondData: [],
      thirdData: [],
      category_id: 0,
      is_qiye_flag: true,
      quantityStatus: false,

      exportPPTVisible: false,
      exportPPTForm: {
        package_name: '', // 套餐方案名称
        package_gift_price: 0, // 0市场总价 1销售总价 2分销总价
        package_goods_price: 1, // 套餐单品市场价展示：0:不显示 1:显示
        package_goods_img: 0, // PPT页面图片展示：0:单品图片 1:套餐礼包图片
        gift_category: 0, // 导出套餐分类页面：0:否 1:是
        gift_home_img: '', // 首页封面图
        gift_category_img: '', // 套餐分类图
        gift_background_img: '' // 普通页面背景图
      },
      exportPPTFormRule: {
        package_name: [
          { required: true, message: '请填写套餐方案名称', trigger: 'blur' }
        ]
      },
      areaList: [],//区域列表
      giftCategoryParams: '',
      JDCategory: $giftsCategory.create(),
      dialogGiftShow: false,//礼包改价弹窗
      giftParams: {//商城套餐查询
        shop_type: '',//来源应用
        labelNamesArea: '',//区域偏好
        comboType: '',//套餐类型
        unselected: 1,
      },
      /** 查看商品种类弹窗*/
      comboGoodsNumVisible: true,
      comboGoodsNumTableData: {},
      giftIdList: [],//礼包id列表
      errorGiftMessage: [],
      errorGiftVisible: false,
      isGlzcShop: false,//是否是国联智诚企业
    };
  },
  watch: {
    "areaSelectData.province": {
      handler (newValue) {
        this.areaSelectData.city = "";
        this.areaSelectData.county = "";
        this.areaSelectData.town = "";
        if (newValue === "") {
          this.areaNum = 1;
          this.getAreaHandle("province");
        } else {
          this.areaNum = 2;
          this.getAreaHandle("city", { id: newValue });
        }
      },
      immediate: true,
      deep: true
    },
    "areaSelectData.city": {
      handler (newValue) {
        this.areaSelectData.county = "";
        this.areaSelectData.town = "";
        if (this.areaSelectData.province === "") return;
        if (newValue === "") this.areaNum = 2;
        else {
          this.areaNum = 3;
          this.getAreaHandle("county", { id: newValue });
        }
      },
      deep: true
    },
    "areaSelectData.county": {
      handler (newValue) {
        this.areaSelectData.town = "";
        if (this.areaSelectData.city === "") return;
        if (newValue === "") this.areaNum = 3;
        else {
          this.areaNum = 4;
          this.getAreaHandle("town", { id: newValue });
        }
      },
      deep: true
    }
  },
  filters: {
    /**来源应用 */
    getShopType (shop_type) {
      switch (shop_type) {
        case 5:
          return "智能套餐卡商城";
        case 7:
          return "小程序商城";
        default:
          return "双选商城";
      }
    }
  },
  async mounted () {
    this.handleGetLoginType();
    //查询区域列表
    getGiftLabelList({ page_no: 1, page_size: 9999, type: 2 }).then((response) => {
      this.areaList = response.data.data;
    });

    let shopidList = await API_goods.getExportShopIds()
    shopidList = shopidList.data.split(',')
    this.isGlzcShop = shopidList.includes((this.$store.state.user.shop.parent_id).toString())
    if (this.$store.getters.isSupplierName && this.isGlzcShop) {//有供应商并且是国联智诚
      this.exportType = 1
    } else {
      if (this.$store.getters.isSupplierName && !this.isGlzcShop) {
        this.exportType = 2
      } else {
        if (!this.$store.getters.isSupplierName && this.isGlzcShop) {
          this.exportType = 3
        } else {//都没有
          this.exportType = 4
        }
      }
    }
  },
  methods: {
    opengoodsclassify (row) {//打开商品详情弹窗
      this.goodsclassifyData = {
        from: "1",
        goods_id: row.goods_id,
        good_sn: row.good_sn,
        shop_profit: row.enterprise_profit,
        islogin: true,
      }
      this.dialogVisible = true
    },
    /**
     * 获取区域数据
     * @param type
     * @param data
     */
    getAreaHandle (type, data = {}) {
      if (type === "province") {
        API_goods.getJDProvince().then(res => {
          this.provinceList = this.dataHandle(res.data.result);
        });
      } else if (type === "city") {
        API_goods.getJDCity(data.id).then(res => {
          this.cityList = this.dataHandle(res.data.result);
        });
      } else if (type === "county") {
        API_goods.getJDCounty(data.id).then(res => {
          this.countyList = this.dataHandle(res.data.result);
        });
      } else if (type === "town") {
        API_goods.getJDTown(data.id).then(res => {
          this.townList = this.dataHandle(res.data.result);
        });
      }
    },
    dataHandle (data) {
      let returnData = [];
      const keys = Object.keys(data);
      const values = Object.values(data);
      keys.forEach((key, index) => {
        const data = {
          name: key,
          id: values[index]
        };
        returnData.push(data);
      });
      return returnData;
    },
    getRowTagImage (row) {
      if (this.params.self_goods === 10 && row.normal_status === 0) return statusErr
      switch (row.good_source) {
        case "自有商品":
          // case 0:
          return TagZY;
        case "京东商品":
          // case 1:
          return TagJD;
        case "普通商品":
          // case 2:
          return TagJX;
      }
    },
    leaveIsYse () {
      if (this.firstValue) {
        if (this.secondData.length > 0) {
          if (this.secondValue) {
            if (this.thirdData.length > 0) {
              if (this.thirdValue) {
                API_goods.addGoods(this.idsList, {
                  goods_ids: this.idsList,
                  goods_source: this.goods_source,
                  category_id: this.thirdValue
                }).then(res => {
                  this.tagShow = false;
                  this.GET_GoodsList();
                });
              }
            } else {
              API_goods.addGoods(this.idsList, {
                goods_ids: this.idsList,
                goods_source: this.goods_source,
                category_id: this.secondValue
              }).then(res => {
                this.tagShow = false;
                this.GET_GoodsList();
              });
            }
          }
        } else {
          API_goods.addGoods(this.idsList, {
            goods_ids: this.idsList,
            goods_source: this.goods_source,
            category_id: this.firstValue
          }).then(res => {
            this.tagShow = false;
            this.GET_GoodsList();
          });
        }
      }
    },
    choosefirst () {
      this.secondData = this.firstData.find(item => {
        return item.cat_id === this.firstValue;
      }).children;
      this.secondValue = "";
    },
    choosesecond () {
      this.thirdData = this.secondData.find(item => {
        return item.cat_id === this.secondValue;
      }).children;
      this.thirdValue = "";
    },
    choosethird () { },
    handleGetLoginType () {
      API_goods.getLoginType().then(res => {
        this.is_qiye_flag = res.parentLogin === 1;
      });
    },
    che () {
      if (this.checked) {
        this.params.filter_id = 1;
        this.giftParams.unselected = 1;
      } else {
        this.params.filter_id = 0;
        this.giftParams.unselected = 0;
      }
      if (this.params.self_goods === 10) return this.getGiftList();
      this.GET_GoodsList();
    },
    close () {
      this.dialogShopShow1 = false;
      this.dialogGiftShow = false;
    },
    res () {
      this.dialogShopShow = true;
      this.dialogShopShow1 = false;
      this.dialogGiftShow = false;
    },
    handleShopPickerConfirm1 (item) {
      this.dialogShopShow1 = false;
      this.pa.shop_price = item.shop_price;
      this.pa.enterprise_price = item.enterprise_price;
      this.pa.shop_goods_status = item.shop_goods_status;
      this.pa.goods_ids = this.idsList;
      let that = this;
      API_goods.sendShop(this.pa).then(res => {
        const refreshSuccessEvent = new CustomEvent("refresh", {
          cancelable: false,
          bubbles: false,
          detail: { msg: "挑选成功" }
        });
        let refreshListener = function (e) {
          that.$message.success(e.detail.msg);
        };
        document.addEventListener("refresh", refreshListener);
        that.GET_GoodsList(refreshSuccessEvent, refreshListener);
      });
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      if (this.params.self_goods === 10) return this.getGiftList();
      this.GET_GoodsList();

    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      if (this.params.self_goods === 10) return this.getGiftList();
      this.GET_GoodsList();
    },

    /** 搜索事件触发 */
    /** 搜索事件触发 */
    searchEvent (data) {
      this.params = {
        ...this.params,
        keyword: data
      };
      this.params.page_no = 1;
      if (this.params.self_goods === 10) return this.getGiftList();
      this.params.category_path = this.category;
      this.GET_GoodsList();
    },

    selectGoods (row) {
      this.idsList = [];
      this.idsList.push(row.goods_id);
      this.giftIdList = [];
      this.giftIdList.push(row.id);

      this.$confirm("是否将所选商品放入到商品列表?", "提示", {
        type: "warning"
      }).then(() => {
        //判断是礼包挑选还是商品挑选
        this.params.self_goods === 10 ? this.dialogGiftShow = true : this.dialogShopShow1 = true;
      });
    },
    selectGoodsAll () {
      this.idsList = this.tempList.map(item => item.goods_id);
      this.giftIdList = this.tempList.map(item => item.id);//礼包批量挑选
      if (!this.idsList.length) {
        this.$message.error("请勾选商品");
        return;
      }
      this.$confirm("是否将所选商品放入到商品列表?", "提示", {
        type: "warning"
      }).then(() => {
        //判断是礼包挑选还是商品挑选
        this.params.self_goods === 10 ? this.dialogGiftShow = true : this.dialogShopShow1 = true;
      });
    },
    handleSelectionChange (list) {
      this.tempList = list;
    },
    handleSortChange ({ prop, order }) {
      if (order) {
        this.params.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"}`;
        this.giftParams.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"}`;
      } else {
        this.params.sort = "";
        this.giftParams.sort = "";
      }
      if (this.params.self_goods === 10) return this.getGiftList();
      this.GET_GoodsList();
    },
    headerParamChange (params) {
      if (params.self_goods === 9) {
        this.categoryProxy = $goodsCategory.create('9');
      } else {
        this.categoryProxy = $goodsCategory.create();
      }
      this.checked = true;
      this.params = {
        page_no: 1,
        page_size: 20,
        // 以下不是京东馆参数
        high_price: "",
        low_price: "",
        high_mktprice: "",
        low_mktprice: "",
        profit_min: "",
        profit_max: "",
        self_goods: 0,
        // 排序
        sort: "",
        // 京东排序
        sort_type: "",
        category_path: "",
        keyword: "",
        filter_id: 1,
        // 京东馆价钱最大值和最小
        min: "",
        max: "",
        cid1: "", //一级分类
        cid2: "", //二级分类
        ...params
      };
      this.areaSelectData = {
        province: "",
        city: "",
        county: "",
        town: "",
        jd_area_state: 0
      },
        this.key_word = "goods_name";
      this.searchContent = "";
      this.category = "";
      this.pageData.page_no = 1;
      if (params.self_goods === 10) return this.getGiftList();
      this.GET_GoodsList();
    },
    GET_GoodsList (refreshSuccessEvent, refreshListener) {
      let params = {
        ...this.params,
        market_enable: 1,
        ...this.areaSelectData,
      };
      params[this.key_word] = this.searchContent;
      if (this.params.self_goods === 2 && this.areaSelectData.province !== "") {
        let jd_area = "" + this.areaSelectData.province + "_";
        if (this.areaSelectData.city !== "")
          jd_area += this.areaSelectData.city + "_";
        else jd_area += "0_";
        if (this.areaSelectData.county !== "")
          jd_area += this.areaSelectData.county + "_";
        else jd_area += "0_";
        if (this.areaSelectData.town !== "")
          jd_area += this.areaSelectData.town;
        else jd_area += "0";
        params.jd_area = jd_area;
        if (!params.jd_area_state) params.jd_area_state = 0;
        this.quantityStatus = true;
      } else {
        delete params.jd_area;
        delete params.jd_area_state;
        this.quantityStatus = false;
      }
      if (this.params.self_goods === 2) {//京东
        params.goods_source = 1
      } else if (this.params.self_goods === 4) {//企业自有供应商
        params.goods_source = 5
      } else {
        params.goods_source = 0
      }
      //蛋糕商品
      if (this.params.self_goods === 9) params.goods_source = 3;
      params.category_path = params.path ? params.path : params.category_path;
      delete params.path;
      if (params.keyword) {
        delete params.self_goods;
        delete params.goods_source;
      }
      // if (params.category_path) {
      //   params.goods_source = ''
      //   params["goods_name"] = ''
      //   params["seller_name"] = ''
      //   params["self_goods"] = ''
      // } else if (params["goods_name"] || params["seller_name"]) {
      //   if (params["goods_name"]) {
      //     params["seller_name"] = ""
      //   } else {
      //     params["goods_name"] = ""
      //   }
      //   params.category_path = ''
      //   params.goods_source = ''
      //   params["self_goods"] = ''
      // } else {
      //   params["goods_name"] = ''
      //   params["seller_name"] = ''
      //   params.category_path = ''
      // }
      if (params["goods_name"]) {
        params["seller_name"] = "";
      } else {
        params["goods_name"] = "";
      }
      if (params.goods_source === 1) {
        params.is_auth = 1;
      }
      if (params.self_goods === 0) {
        params.goods_source = "";
        params.self_goods = "";
      }
      API_goods.getGoodsListq(params).then(response => {
        this.pageData = response;
        // 重新绘制表格
        this.$refs.tableLayout.$refs.table.doLayout();
        if (refreshSuccessEvent) {
          document.dispatchEvent(refreshSuccessEvent);
          document.removeEventListener("refresh", refreshListener);
        }
      });
    },
    /**查询套餐列表 */
    getGiftList () {
      const { page_no, page_size } = this.params;
      const {
        lowShopSumPrice,
        highShopSumPrice,
        lowMktPrice,
        highMktPrice,
        shop_type,
        lowMarketProfit,
        highMarketProfit,
        labelNamesArea,
        comboType,
        normal_status,
        lowComboDiscount,
        highComboDiscount,
        sort,
        unselected,
      } = this.giftParams;
      let err = [];
      if (
        !this.isEmpty(lowShopSumPrice) &&
        !this.isEmpty(highShopSumPrice) &&
        Number(lowShopSumPrice) > Number(highShopSumPrice)
      ) {
        err.push("最低会员总价应小于等于最高会员总价");
      }
      if (
        !this.isEmpty(lowMktPrice) &&
        !this.isEmpty(highMktPrice) &&
        Number(lowMktPrice) > Number(highMktPrice)
      ) {
        err.push("最低市场总价应小于等于最高会员总价");
      }
      if (
        !this.isEmpty(lowMarketProfit) &&
        !this.isEmpty(highMarketProfit) &&
        Number(lowMarketProfit) > Number(highMarketProfit)
      ) {
        err.push("最低市场利润率应小于等于最高市场利润率");
      }
      if (
        !this.isEmpty(lowComboDiscount) &&
        !this.isEmpty(highComboDiscount) &&
        Number(lowComboDiscount) > Number(highComboDiscount)
      ) {
        err.push("最低套餐折扣应小于等于最高套餐折扣");
      }
      if (err.length) {
        for (let i = 0; i < err.length; i++) {
          setTimeout(() => {
            this.$message.error(err[i]);
          }, 300 * i);
        }
        this.loading = false;
        return;
      }
      let params = {
        page_no,
        page_size,
        lowShopSumPrice,
        highShopSumPrice,
        lowMktPrice,
        highMktPrice,
        shop_type,
        lowMarketProfit,
        highMarketProfit,
        labelNamesArea,
        comboType,
        normal_status,
        lowComboDiscount,
        highComboDiscount,
        sort,
        unselected
      };
      params.categoryPath = this.giftCategoryParams;
      params[this.key_word] = this.searchContent;
      API_goods.getEnterpriseCombo(params).then(response => {
        this.loading = false;
        this.tableData = response.data;
        this.pageData = response;
        // 重新绘制表格
        this.$refs.tableLayout.$refs.table.doLayout();
      });
    },
    exportbatchOps (ops) {//导出PPT
      switch (ops) {
        case 'exportPPT':
          return this.importMore();
        case 'twoExportPPT':
          return this.importMoreTwo();
        case 'exportExcel':
          return this.exportMore();
      }
    },
    //站点订单列表导出PPT方法
    importMore () {
      if (this.tempList.length == 0) {
        this.$message.error("请勾选礼包");
        return;
      }
      const list = this.tempList.map(item => item.id)
      API_goods.getImportRecordList({ combo_ids: list, page_no: 1, page_size: 9999 }).then(res => {
        let a = document.createElement('a');//创建a标签
        a.href = res;//文件url
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();//触发下载
        document.body.removeChild(a);
        this.$message.success('导出成功')
      })
    },
    giftCategoryChange (val) {
      if (val == 0) {
        this.exportPPTForm.gift_category_img = ''
      }
    },
    //导出PPT方案2
    importMoreTwo () {
      if (this.tempList.length == 0) {
        this.$message.error("请勾选礼包");
        return;
      }
      this.exportPPTVisible = true
    },
    resetPPTForm () {//重置表单
      this.exportPPTForm = {
        package_name: '', // 套餐方案名称
        package_gift_price: 0, // 0市场总价 1销售总价 2分销总价
        package_goods_price: 1, // 套餐单品市场价展示：0:不显示 1:显示
        package_goods_img: 0, // PPT页面图片展示：0:单品图片 1:套餐礼包图片
        gift_category: 0, // 导出套餐分类页面：0:否 1:是
        gift_home_img: '', // 首页封面图
        gift_category_img: '', // 套餐分类图
        gift_background_img: '' // 普通页面背景图
      }
    },
    exportPPTConfirm () {
      this.$refs['exportPPTForm'].validate(valid => {
        if (valid) {
          const dataform = Object.fromEntries(Object.entries(this.exportPPTForm).filter(([key, value]) => value || value === 0))
          API_goods.newExportComboList({ ...dataform, combo_ids: this.tempList.map(item => item.id) }).then(res => {
            let a = document.createElement('a');//创建a标签
            a.href = res;//文件url
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();//触发下载
            document.body.removeChild(a);
            this.$message.success('导出成功')
            this.exportPPTVisible = false
            this.resetPPTForm()
          })
        }
      })
    },
    //站点订单列表导出Excel方法
    exportMore () {
      if (this.tempList.length == 0) {
        this.$message.error("请勾选礼包");
        return;
      }
      this.importLoading = true;
      let exportExcel = {
        type: this.exportType,//类型
        gift_list: []//礼包集合
      }
      this.tempList.map(item => {
        let obj = {//格式化单个礼包数值
          goods_list: [],//商品集合
          sub_totals: []//合计
        };
        if (this.exportType == 1) {//企业挑战点  都有供应商和大平台成本价
          let all_admin_cost = 0 //大后台（成本价）合计
          item.goods_volist.map(els => {

            //判断是否是京东商品
            let jdUrl = '';//京东链接
            let goods_void = els.shop_goods_vo.goods_do.sn;//商品编码
            let adminCost = els.shop_goods_vo.goods_do.cost
            //自有商品
            if (els.shop_goods_vo.goods_do.goods_source == 0 && els.shop_goods_vo.goods_do.self_operated === 1) {
              adminCost = ''
            }
            if (els.supplier_name == '京东供应商') {
              jdUrl = `https://item.jd.com/${els.shop_goods_vo.goods_do.ext_goods_id}.html`;//京东链接
              goods_void = els.shop_goods_vo.goods_do.ext_goods_id;//商品编码
            }
            if (adminCost) {
              all_admin_cost = this.addPrice(all_admin_cost, this.accMul(adminCost, els.num))
            }
            let arrays = [
              item.id,//编号
              item.category_name,//套餐分类
              item.label_names_gift,//标签
              els.goods_name,//商品名称
              els.num,//数量
              "",//图片占位（空）
              "",//图片占位（空）
              "",//图片占位（空）
              "",//参数（空）
              "",//产品说明（空）
              els.shop_goods_vo.goods_do.mktprice,//市场价
              els.shop_goods_vo.revise_price,//销售价
              item.shop_sum_price,//套餐价
              jdUrl,//京东链接
              '',//报价截止时间（空）
              goods_void,//商品编码
              els.shop_goods_vo.enterprise_revise_price,//分销价
              '',//分销利润率
              '',//成本（空）
              adminCost,//成本价（大后台）
              '',//运费（空）
              '',//仓储成本（空）
              '',//销售提成（空）
              '',//卡券成本（空）
              ['已下架', '已上架'][els.shop_goods_vo.shop_goods_status],//商品状态
              els.supplier_name,//供应商名称
              '',//成本最终（空）
              '',//最终毛利率（空）
              '',//采购提成（空）
              item.remark,//备注
              els.shop_goods_vo.goods_do.original,//商品图片
            ]
            obj.goods_list.push(arrays)
          })
          obj.sub_totals = [
            '',
            '',
            '',
            '合计',
            '',
            '',
            '',
            '',
            '',
            '',
            item.market_sum_price,//礼包市场总价
            item.shop_sum_price,//礼包销售总价
            item.shop_sum_price,//礼包销售总价
            '',
            '',
            '',
            item.enterprise_sum_price,//礼包分销总价
            item.distribution_profit ? item.distribution_profit.toFixed(2) : 0,//分销利润率,//礼包分销利润率
            '',
            all_admin_cost || '',//成本价（大后台）
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',//商品图片占位
          ]
        } else if (this.exportType == 2) {//企业挑战点  没有大平台成本价
          item.goods_volist.map(els => {
            //判断是否是京东商品
            let jdUrl = '';//京东链接
            let goods_void = els.shop_goods_vo.goods_do.sn;//商品编码
            if (els.supplier_name == '京东供应商') {
              jdUrl = `https://item.jd.com/${els.shop_goods_vo.goods_do.ext_goods_id}.html`;//京东链接
              goods_void = els.shop_goods_vo.goods_do.ext_goods_id;//商品编码
            }
            let arrays = [
              item.id,//编号
              item.category_name,//套餐分类
              item.label_names_gift,//标签
              els.goods_name,//商品名称
              els.num,//数量
              "",//图片占位（空）
              "",//图片占位（空）
              "",//图片占位（空）
              "",//参数（空）
              "",//产品说明（空）
              els.shop_goods_vo.goods_do.mktprice,//市场价
              els.shop_goods_vo.revise_price,//销售价
              item.shop_sum_price,//套餐价
              jdUrl,//京东链接
              '',//报价截止时间（空）
              goods_void,//商品编码
              els.shop_goods_vo.enterprise_revise_price,//分销价
              '',//分销利润率
              '',//成本（空）

              '',//运费（空）
              '',//仓储成本（空）
              '',//销售提成（空）
              '',//卡券成本（空）
              ['已下架', '已上架'][els.shop_goods_vo.shop_goods_status],//商品状态
              els.supplier_name,//供应商名称
              '',//成本最终（空）
              '',//最终毛利率（空）
              '',//采购提成（空）
              item.remark,//备注
              els.shop_goods_vo.goods_do.original,//商品图片
            ]
            obj.goods_list.push(arrays)
          })
          obj.sub_totals = [
            '',
            '',
            '',
            '合计',
            '',
            '',
            '',
            '',
            '',
            '',
            item.market_sum_price,//礼包市场总价
            item.shop_sum_price,//礼包销售总价
            item.shop_sum_price,//礼包销售总价
            '',
            '',
            '',
            item.enterprise_sum_price,//礼包分销总价
            item.distribution_profit ? item.distribution_profit.toFixed(2) : 0,//分销利润率,//礼包分销利润率
            '',

            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',//商品图片占位
          ]
        } else if (this.exportType == 3) {//企业跳站点  没有供应商名称
          let all_admin_cost = 0 //大后台（成本价）合计
          item.goods_volist.map(els => {
            //判断是否是京东商品
            let jdUrl = '';//京东链接
            let goods_void = els.shop_goods_vo.goods_do.sn;//商品编码
            let adminCost = els.shop_goods_vo.goods_do.cost
            //自有商品
            if (els.shop_goods_vo.goods_do.goods_source == 0 && els.shop_goods_vo.goods_do.self_operated === 1) {
              adminCost = ''
            }
            if (els.supplier_name == '京东供应商') {
              jdUrl = `https://item.jd.com/${els.shop_goods_vo.goods_do.ext_goods_id}.html`;//京东链接
              goods_void = els.shop_goods_vo.goods_do.ext_goods_id;//商品编码
            }
            if (adminCost) {
              all_admin_cost = this.addPrice(all_admin_cost, this.accMul(adminCost, els.num))
            }
            let arrays = [
              item.id,//编号
              item.category_name,//套餐分类
              item.label_names_gift,//标签
              els.goods_name,//商品名称
              els.num,//数量
              "",//图片占位（空）
              "",//图片占位（空）
              "",//图片占位（空）
              "",//参数（空）
              "",//产品说明（空）
              els.shop_goods_vo.goods_do.mktprice,//市场价
              els.shop_goods_vo.revise_price,//销售价
              item.shop_sum_price,//套餐价
              jdUrl,//京东链接
              '',//报价截止时间（空）
              goods_void,//商品编码
              els.shop_goods_vo.enterprise_revise_price,//分销价
              '',//分销利润率
              '',//成本（空）
              adminCost,//成本价（大后台）
              '',//运费（空）
              '',//仓储成本（空）
              '',//销售提成（空）
              '',//卡券成本（空）
              ['已下架', '已上架'][els.shop_goods_vo.shop_goods_status],//商品状态

              '',//成本最终（空）
              '',//最终毛利率（空）
              '',//采购提成（空）
              item.remark,//备注
              els.shop_goods_vo.goods_do.original,//商品图片
            ]
            obj.goods_list.push(arrays)
          })
          obj.sub_totals = [
            '',
            '',
            '',
            '合计',
            '',
            '',
            '',
            '',
            '',
            '',
            item.market_sum_price,//礼包市场总价
            item.shop_sum_price,//礼包销售总价
            item.shop_sum_price,//礼包销售总价
            '',
            '',
            '',
            item.enterprise_sum_price,//礼包分销总价
            item.distribution_profit ? item.distribution_profit.toFixed(2) : 0,//分销利润率,//礼包分销利润率
            '',
            all_admin_cost || '',//成本价（大后台）
            '',
            '',
            '',
            '',
            '',

            '',
            '',
            '',
            '',
            '',//商品图片占位
          ]
        } else if (this.exportType == 4) {//企业跳站点  都没有供应商和大平台成本价
          item.goods_volist.map(els => {
            //判断是否是京东商品
            let jdUrl = '';//京东链接
            let goods_void = els.shop_goods_vo.goods_do.sn;//商品编码
            if (els.supplier_name == '京东供应商') {
              jdUrl = `https://item.jd.com/${els.shop_goods_vo.goods_do.ext_goods_id}.html`;//京东链接
              goods_void = els.shop_goods_vo.goods_do.ext_goods_id;//商品编码
            }
            let arrays = [
              item.id,//编号
              item.category_name,//套餐分类
              item.label_names_gift,//标签
              els.goods_name,//商品名称
              els.num,//数量
              "",//图片占位（空）
              "",//图片占位（空）
              "",//图片占位（空）
              "",//参数（空）
              "",//产品说明（空）
              els.shop_goods_vo.goods_do.mktprice,//市场价
              els.shop_goods_vo.revise_price,//销售价
              item.shop_sum_price,//套餐价
              jdUrl,//京东链接
              '',//报价截止时间（空）
              goods_void,//商品编码
              els.shop_goods_vo.enterprise_revise_price,//分销价
              '',//分销利润率
              '',//成本（空）

              '',//运费（空）
              '',//仓储成本（空）
              '',//销售提成（空）
              '',//卡券成本（空）
              ['已下架', '已上架'][els.shop_goods_vo.shop_goods_status],//商品状态

              '',//成本最终（空）
              '',//最终毛利率（空）
              '',//采购提成（空）
              item.remark,//备注
              els.shop_goods_vo.goods_do.original,//商品图片
            ]
            obj.goods_list.push(arrays)
          })
          obj.sub_totals = [
            '',
            '',
            '',
            '合计',
            '',//数量
            '',//图片
            '',//图片
            '',//图片
            '',
            '',
            item.market_sum_price,//礼包市场总价
            item.shop_sum_price,//礼包销售总价
            item.shop_sum_price,//礼包销售总价
            '',
            '',
            '',
            item.enterprise_sum_price,//礼包分销总价
            item.distribution_profit ? item.distribution_profit.toFixed(2) : 0,//分销利润率,//礼包分销利润率
            '',

            '',
            '',
            '',
            '',
            '',

            '',
            '',
            '',
            '',
            '',//商品图片占位
          ]
        }
        exportExcel.gift_list.push(obj)
      })
      API_goods.exportExcelComboList(exportExcel).then(res => {
        let a = document.createElement('a');//创建a标签
        a.href = res;//文件url
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();//触发下载
        document.body.removeChild(a);
        this.$message.success('导出成功')
        this.importLoading = true;
      })
    },
    /**商品种类弹窗*/
    handleComboGoodsNum (row) {
      console.log(row)
      this.comboGoodsNumVisible = true
      this.comboGoodsNumTableData.data = []
      row.goods_volist.forEach(item => {
        let {
          web_thumbnail,
          supplier_name,
          goods_alias,
          goods_name,
          num,
          shop_goods_vo: {
            goods_do: { goods_source, thumbnail, self_operated }
          }
        } = item
        this.comboGoodsNumTableData.data.push({
          goods_name,
          goods_alias,
          goods_source,
          self_operated,
          num,
          web_thumbnail,
          thumbnail,
          supplier_name
        })
      })
    },
    /**
     * 两位小数加法
     */
    addPrice (a, b) {
      a = a.toString()
      b = b.toString()
      a.split('.')[1] ? a.split('.')[1].length === 1 && (a += '0') : (a += '00')
      b.split('.')[1] ? b.split('.')[1].length === 1 && (b += '0') : (b += '00')
      return (Number(a.replace('.', '')) + Number(b.replace('.', ''))) / 100
    },
    // 一个高精度乘法计算的函数
    accMul (arg1, arg2) {
      let m = 0
      let s1 = arg1.toString()
      let s2 = arg2.toString()
      try {
        m += s1.split('.')[1].length
      } catch (e) { }
      try {
        m += s2.split('.')[1].length
      } catch (e) { }
      return (
        (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
        Math.pow(10, m)
      )
    },
    /**挑选商城后保存礼包改价操作 */
    async handleGiftConfirm (item) {
      this.dialogGiftShow = false;
      const datas = {
        ...item,
        combo_ids: this.giftIdList,
        shop_id: this.$store.state.user.shop.shop_id,
        is_continue: 0//是否继续挑选
      };
      const res = await API_goods.selectGoodsCombo(datas)
      if (res.gift_name && res.gift_name.length > 0) {//以下礼包的礼包名称在所选商城中已经存在
        this.errorGiftMessage = res.gift_name;
        this.errorGiftVisible = true;
      } else if (res.error) {
        this.$confirm('当前礼包列表中已存在相同商品数据类型的礼包，是否继续？', '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '继续',
          cancelButtonText: '取消'
        })
          .then(() => {
            API_goods.selectGoodsCombo({ ...datas, is_continue: 1 }).then(res => {
              this.$message.success("挑选成功");
              // this.getGiftList();
            })
          }).catch(action => { });
      } else {
        this.$message.success("挑选成功");
        // this.getGiftList();
      }
    },
    handleShopPickerConfirm1 (item) {
      this.dialogShopShow1 = false;
      this.pa.shop_price = item.shop_price;
      this.pa.enterprise_price = item.enterprise_price;
      this.pa.shop_goods_status = item.shop_goods_status;
      this.pa.goods_ids = this.idsList;
      let that = this;
      API_goods.sendShop(this.pa).then(res => {
        const refreshSuccessEvent = new CustomEvent("refresh", {
          cancelable: false,
          bubbles: false,
          detail: { msg: "挑选成功" }
        });
        let refreshListener = function (e) {
          that.$message.success(e.detail.msg);
        };
        document.addEventListener("refresh", refreshListener);
        that.GET_GoodsList(refreshSuccessEvent, refreshListener);
        // API_goods.addGoods(that.idsList, {
        //   goods_ids: that.idsList,
        //   goods_source: that.goods_source
        // }).then(res1 => {

        // }).catch(err => {
        //   that.$message.error('挑选失败');
        // });
      });
    },
    isEmpty (value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value == "object" && Object.keys(value).length == 0) ||
        (typeof value == "string" && value.trim().length == 0)
      );
    },
    /**搜索价格正则校验*/
    searchInput (val) {
      const reg = /^(0|[1-9]\d*)(\.\d{1,2})?$/
      const regex = /^-?0$|^-?\d+(\.\d{1,2})?$/;
      if (['lowMarketProfit', 'highMarketProfit'].includes(val)) {//市场利润率允许输入负数
        if (!regex.test(this.giftParams[val])) this.giftParams[val] = ''
      } else {
        if (!reg.test(this.giftParams[val])) this.giftParams[val] = ''
      }
    }
  }
};
</script>
<style type="text/scss" lang="scss" scoped>
.num {
  cursor: pointer;
  color: #2155d5;
  text-decoration: underline;
}

.num:hover {
  text-decoration: underline;
}

.exportPPTForm {
  .el-radio {
    margin-top: 7px
  }

  /deep/ .site-logo {
    .deleteImg {
      position: absolute;
      right: -6px;
      top: -5px;
    }

    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      // overflow: hidden;

      &:hover {
        border-color: #409eff;
      }
    }
  }

  .site-logo-img {
    width: 120px;
    height: 120px;
    display: block;
  }

  .logo-uploader-icon {
    font-size: 20px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
}

@import "@/components/GoodsClassify/styles";
</style>
<style lang="scss">
.goodsclassify {
  .el-dialog__body {
    padding: 0 !important;
  }
}

.el-row {
  width: 100%;
}

.address-border {
  width: 100%;
  padding: 10px;
  border: 1px solid #e9ebf0;
  border-radius: 5px;
}
</style>
